<template>
  <sb-page-content title="Remittance Advice"
                   class="back-button-margin">
    <v-container class="pa-0">
      <ValidationObserver ref="obs"
                          v-slot="{invalid, passes}">
        <v-toolbar dark
                   color="secondary"
                   class="mb-2 toolbar-rounded search-padding">
          <v-row>
            <v-col>
              <sb-text-field v-model="search.merchantId"
                             :disabled="$wait.any"
                             :readonly="search.isMerchant"
                             rules="required"
                             sb-solo
                             clearable
                             flat
                             light
                             hide-details
                             prepend-inner-icon="mdi-card-account-details-outline"
                             label="Merchant ID" />
            </v-col>
            <v-col>
              <sb-date-range-picker-field v-model="search.dateRange"
                                          ref-name="remittanceDateRange"
                                          rules="required"
                                          sb-solo
                                          sort-dates
                                          @clear="clearDateRangeSearch" />
            </v-col>

            <v-col>
              <v-btn color="white"
                     light
                     large
                     depressed
                     class="float-right"
                     :loading="searchLoading"
                     :disabled="$wait.any || invalid || !isDateRangeSelected"
                     @click="passes(searchMerchantRemittance)">
                <v-icon left
                        color="secondary">
                  mdi-magnify
                </v-icon>
                <span class="secondary--text">Search</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </ValidationObserver>

      <sb-loader v-if="searchLoading"
                 class="mt-5" />

      <template v-else>
        <sb-empty-message v-if="remittanceAdvices.length === 0"
                          class="mt-5"
                          message="No Remittance Advices to display."
                          sub-message="Start by search for a Merchant above." />

        <template v-else>
          <sb-merchant-detail v-if="merchantDetails.merchant.merchantNumber"
                              :merchant-details="merchantDetails"
                              hide-services
                              class="mt-5" />

          <v-expansion-panels multiple
                              hover
                              class="mt-5">
            <template v-for="(advice, index) in remittanceAdvices">
              <v-expansion-panel :key="index">
                <v-expansion-panel-header>
                  <template #default>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <v-tooltip bottom>
                          <template #activator="{on}">
                            <span v-on="on">{{ advice.paymentDate }}</span>
                          </template>
                          <span>Payment Date</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4">
                        <v-tooltip bottom>
                          <template #activator="{on}">
                            <span v-on="on">{{ advice.paymentTotal | currency }}</span>
                          </template>
                          <span>Payment Batch Total</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4">
                        <v-tooltip bottom>
                          <template #activator="{on}">
                            <span v-on="on">{{ advice.merchantBank }}</span>
                          </template>
                          <span>Merchant Bank Details</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-data-table :headers="REMITTANCE_TABLE_HEADERS"
                                :items="advice.items"
                                :items-per-page="advice.items.length"
                                hide-default-footer>
                    <template #item.authorisationAmount="{item}">
                      {{ item.authorisationAmount | currency }}
                    </template>

                    <template #item.vatAmount="{item}">
                      {{ item.vatAmount | currency }}
                    </template>

                    <template #item.commission="{item}">
                      {{ item.commission | currency }}
                    </template>

                    <template #item.paymentAmount="{item}">
                      {{ item.paymentAmount | currency }}
                    </template>

                    <template #footer>
                      <v-row>
                        <v-col>
                          <v-btn color="secondary"
                                 class="float-right"
                                 depressed
                                 :loading="$wait.is('downloadRemittanceAdviceLoading')"
                                 :disabled="$wait.any"
                                 @click="downloadRemittance(advice.paymentDate)">
                            <v-icon left>
                              mdi-cloud-download
                            </v-icon>
                            <span>Download</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </template>
      </template>
    </v-container>
  </sb-page-content>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import SbMerchantDetail from '@/components/merchant/SbMerchantDetail';

import { REMITTANCE_TABLE_HEADERS } from '@/config/constants';
import { base64toBlob } from '@/utils';

export default {
  components: {
    SbMerchantDetail,
    ValidationObserver,
  },

  props: {},

  data() {
    return {
      REMITTANCE_TABLE_HEADERS,
      search: {
        merchantId: '',
        dateRange: [],
        displayDateRange: false,
        isMerchant: false,
      },

      merchantDetails: {
        merchant: {
          merchantNumber: '',
          merchantName: '',
          contactDetails: '',
        },
      },
      isDateRangeSelected: false,
    };
  },

  computed: {
    ...mapGetters({
      remittanceAdvices: 'remittance/remittanceAdvices',
    }),

    dateRangeText() {
      return this.search.dateRange.join(' to ');
    },

    searchLoading() {
      return this.$wait.is('searchRemittanceAdviceLoading') || this.$wait.is('merchantDetailsLoading');
    },
  },

  watch: {
    'search.dateRange': {
      handler(value) {
        if (value.length === 2) {
          this.isDateRangeSelected = true;
        } else {
          this.isDateRangeSelected = false;
        }
      },
    },
  },

  created() {
    this.$store.commit('remittance/remittanceAdvices', []);
    this.search.isMerchant = this.authUser.isMerchant;
    if (this.search.isMerchant) {
      this.search.merchantId = this.authUser.username;
    }
  },

  methods: {
    ...mapActions('remittance', ['searchRemittanceAdvice', 'downloadRemittanceAdvice']),
    ...mapActions('merchants', ['getMerchantDetails']),

    ...mapWaitingActions('remittance', {
      searchRemittanceAdvice: 'searchRemittanceAdviceLoading',
      downloadRemittanceAdvice: 'downloadRemittanceAdviceLoading',
    }),

    ...mapWaitingActions('merchants', {
      getMerchantDetails: 'merchantDetailsLoading',
    }),

    async searchMerchantRemittance() {
      const promises = [];
      promises.push(this.getMerchantDetails(this.search.merchantId));
      promises.push(this.searchRemittanceAdvice(this.search));

      const promiseResults = await Promise.all(promises);

      const merchant = promiseResults[0];
      this.merchantDetails.merchant.merchantNumber = merchant.id;
      this.merchantDetails.merchant.merchantName = merchant.name;
      this.merchantDetails.merchant.contactDetails = merchant.contactDetails;
    },

    async downloadRemittance(paymentDate) {
      const payload = {
        ...this.search,
        ...{ dateRange: [paymentDate, paymentDate] },
      };

      const downloadObject = await this.downloadRemittanceAdvice(payload);
      const blob = base64toBlob(downloadObject.fileData);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = downloadObject.filename;
      link.click();
    },

    clearDateRangeSearch() {
      this.isDateRangeSelected = false;
    },
  },
};
</script>
